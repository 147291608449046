<template>
  <div>
    <img src="@/assets/img/yidao3instruction.png" style="width:100%;height:auto" alt="" srcset="">
  </div>
</template>
<script>
export default {

}
</script>

<style lang="less" scoped>

</style>
